import { Component ,Inject, PLATFORM_ID} from '@angular/core';
export let browserRefresh = false;
import { Subscription } from 'rxjs';
import { CommonService } from '../app/common.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {HomeService} from './home/home.service';
import { NavigationStart, Router  ,NavigationEnd} from '@angular/router';
import {PlayerService} from '../app/player/player.service';
import  { AuthenticationService } from '../app/home/authentication.service';
import {Howl} from '../../node_modules/howler/dist/howler.core.min.js';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';  
import {Toaster} from "ngx-toast-notifications";
declare const ga: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  user;

  title = 'radiofm';
  activetrack = null;
  player:Howl = null;
  isplaying = false;
  subscription: Subscription;
  routesubscription: Subscription;
  isloading = true;
  isDisabled = true;
  isplayerror = false;
  ispageload = false;
  isfav =  false;
  stationId =  null;;
  volume = 0;
  bvolume = 0;
  ngvolume = 100;
  isMobile = false;
  prevnextdata;
  currentindex = 1;
  isprevNext = false;
  isprevNextplaying = false;
  template: string =`<video width="400" loop autoplay><source src="https://onimgspace.s3.ap-south-1.amazonaws.com/assets/loader.mp4" type="video/mp4">Your browser does not support HTML video.</video>`
 
 
  constructor(private authenticationService:AuthenticationService,@Inject(PLATFORM_ID) private platformId: any ,private CommonService: CommonService ,  private SpinnerService: Ng4LoadingSpinnerService  , private playerService: PlayerService ,public homeService:HomeService, private router: Router,@Inject(DOCUMENT) private document: Document,private toaster: Toaster) {
     // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.router.errorHandler = (error: any) => {
      let routerError = error.toString();
      console.log("error",error);
      if (routerError.indexOf('Cannot match any routes') >= 0 ) {
        if(routerError.indexOf('google_vignette') >= 0){

        } else {
          console.log('Cannot match any routes');
            this.router.navigate(['/404']);

        }
        //let result = routerError.includes("google_vignette");

     
      } else {
         throw error;
        
      }
  }
    if (isPlatformBrowser(this.platformId)) {
    
      $(document).on('click', '.sidebarToggle', function(){
        $("app-sidebar").toggleClass("open");
      })
      $(document).on('click', '.closeSidebar, .sidebar li a', function(){
        $("app-sidebar").removeClass("open");
      })
      this.user =  JSON.parse(sessionStorage.getItem('user')) || null;
     
        }

    this.routesubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
   });


 

    this.CommonService.currentLoader.subscribe(state =>  {
     
      this.isloading = state;
    });
    this.CommonService.currentDisabled.subscribe(state =>  {
     
      this.isDisabled = state;
    });

    this.CommonService.currentiserror.subscribe(state =>  {
     
      this.isplayerror = state;
    });
    this.CommonService.currentispageload.subscribe(state =>  {
 
      this.ispageload = state;
    
    });
   this.CommonService.currentisplay.subscribe(state =>  {
    this.isplaying = state;
  
     //if(state){
      //this.start(this.activetrack);
     //}
    });
    this.CommonService.currentvolume.subscribe(state =>  {
      this.volume = state;
      this.bvolume = state;
      this.ngvolume = this.volume * 100;
    });
    this.subscription = this.CommonService.currentTrack.subscribe(message =>  {
  
      if(message){
      //  console.log('currentTrack', message);

          this.activetrack = message.activetrack;
          this.stationId = message.activetrack.st_id;
          this.document.body.classList.add('playerActive');
          if (isPlatformBrowser(this.platformId)) {
            this.isfavourite();
          }
         
     }
   });
    this.subscription = this.CommonService.currentMessage.subscribe(message =>  {
      if(message){
      //  console.log('currentMessage', message);
       // this.activetrack = message.activetrack;
        this.player =  message.player;
        this.isplaying = message.player.playing();
        this.stationId = message.activetrack.st_id;
        this.document.body.classList.add('playerActive');
        setTimeout(function(){
          document.querySelector('.playerView').classList.add('close')
        }, 200);
        ////console.log('aapactivetrack',this.activetrack);
      }
    });

    this.CommonService.currentisFav.subscribe(state =>  {
     // //console.log('currentisFav',state);

      this.isfav = state;
    });
    this.CommonService.currentiscFav.subscribe(state =>  {
     // //console.log('appscurrentiscFav',state);
     if(state){
      if (isPlatformBrowser(this.platformId)) {
       this.isfavourite();
      }
     }
  
    });
    this.CommonService.currentStart.subscribe(state =>  {
      ////console.log('state',state);
      if(state) {
         this.start(this.activetrack);
      }
    });
    this.CommonService.currentnextplay.subscribe(prevnextdata =>  {
      console.log("prevnextdata",prevnextdata);
      this.prevnextdata = prevnextdata;
      this.currentindex = this.prevnextdata.offset;
    });
    this.CommonService.currentisnext.subscribe(state =>  {
      this.isprevNext = state;
      console.log("this.isprevNext",this.isprevNext);
     });
     this.CommonService.currentisprevNextplaying.subscribe(state =>  {
      this.isprevNextplaying = state;
      console.log("this.isprevNextplaying",this.isprevNextplaying);
     });
  }
  pushTrack(){

    this.CommonService.changeMessage({'player': this.player , 'activetrack':  this.activetrack});
  }
  newstart(event, track){
    event.preventDefault();
    event.stopPropagation();
    this.start(track);

  }
  async start(track) {
    
    this.CommonService.setisstartplaying(track);
   // //console.log('appstart', track);
   
   // //console.log(track);
 
   // //console.log('homeactivetrack',this.activetrack);
   // this.SpinnerService.show();  
  //  if(this.activetrack && (this.activetrack.st_id === this.stationId)) {
  //   console.log('sameeeeeeeeeeeeeeeeeeee');
  //   if(this.player) {
  //     console.log('playinggggggggggggggggg');
  //     this.player.stop();
  //   }
  // }
   if(this.player) {
    this.player.stop();
  }
    this.CommonService.setispageload(false);
    this.CommonService.setLoader(true);
    this.CommonService.setDisabled(true);
    this.CommonService.setisprevNextplaying(false);

    const self = this;
    // set stream link 
    this.stationId = track.st_id;
    var stream_link = track.st_link;
    var protocol 		= stream_link.substr(0,4);
    var extension = stream_link.slice(-4)


  
    this.CommonService.changeCurrentTrack({'activetrack':  track});
   
      this.activetrack = track;
      this.isMobile = $(window).width() >= 500;
      if (this.isMobile && (extension == '.pls' || extension == 'm3u8')) {
        $("#openinApp").fadeIn();
        if(this.player) {
          this.player.stop();
        }
        this.CommonService.setispageload(true);
        this.CommonService.setLoader(false);
        this.CommonService.setDisabled(false);
        this.CommonService.setisplayerror(false);
        return false;
      }
          if (extension == '.pls' || extension == '.m3u') {
          
           //console.log('pls');
        
      
        
          const promise = this.getStream(stream_link);
          stream_link = await promise;
          //console.log('value',stream_link);
          
        } 
        if ( protocol == 'http' ) {
          //console.log('http');
          var typeCheck       = stream_link.substr(stream_link.length -4);	
        // 	//console.log(typeCheck);
          var isnum = /^\d+$/.test(typeCheck);
          if ( isnum ) {
            //console.log('isnum');
            var slashCheck       = stream_link.substr(stream_link.length -1);	
          //	//console.log(slashCheck + "slashCheck");
            if (slashCheck == "/") {
              stream_link += ";stream/1"
            }
            else{
              stream_link += "/;stream/1"
            }
            
          }
          ////console.log( isnum );
      }	
      else{
        stream_link = "";
       // link1		= "Currently not available for playing.";
      }
      //console.log('new play',this.player);
      if(this.player) {
        this.player.stop();
      }
      var storedNames = JSON.parse(localStorage.getItem("radioApprecent"));
      if(storedNames) {
        var lucky = storedNames.filter(function(number) {
          return number.st_id != track.st_id;
        });
      
       lucky.push(track);
       if (lucky.length > 100) {
        lucky =  lucky.slice(lucky.length - 100);
      } else {
        //return lucky;
      }
       localStorage.setItem("radioApprecent", JSON.stringify(lucky));
      } else {
       var recents = [];
        recents.push(track);
        localStorage.setItem('radioApprecent', JSON.stringify(recents));
      }
      var error = "Station Not Available";
      if(track.type){
          error = "Song Not Available";
      }
      // console.log("protocol",protocol);
      // if ( protocol == 'http' ) {
      //   stream_link = 'https://theonestopradio.com/stream?url='+stream_link;
      // }
      if( stream_link.indexOf("https") == 0 ) {
      
      }else if(protocol == 'http'){
        stream_link = 'https://theonestopradio.com/stream?url='+stream_link;
      }
     
    
      this.player = new Howl({
        src: stream_link,
        format: ['mp3', 'aac','mpeg','opus','ogg','oga','wav','caf','m4a','mp4','weba','webm','dolby','flac'],
        html5: true,
        volume: 1,
        headers: {
          'Accept': 'audio/*',
          'Authorization': `Bearer`,
       },
        onload:()  =>  {
          self.player.play();
          self.pushTrack();
          
          //console.log('onload');
        },
        onplay: () =>  {
          
          //console.log('onplay');
          if(self.volume == 0){
            //console.log("self.volume",self.volume);
            self.volume = 1;
            self.bvolume =  1;
            self.ngvolume = 100;
            self.CommonService.setvolume(1);
          }
          if(self.volume == 0.0001 ){
            self.bvolume =  0;
            self.CommonService.setbvolume(0);
          }
    
          self.isplaying = true;
          self.CommonService.setLoader(false);
          self.CommonService.setDisabled(false);
          self.CommonService.setisplayerror(false);
          self.CommonService.setisplay(true);
          self.pushTrack();
         
          
        },
        onend: () =>  {
        
          self.isplaying = false;
          //console.log('onend');
          self.pushTrack();
          self.CommonService.setLoader(false);
          self.CommonService.setisplayerror(true);
          self.CommonService.setisend(true);
          self.CommonService.setisplay(false);
          //self.commonService.setisplayerror(true);
        },
        onplayerror : function() {
          //console.log('audio play denied!');
          // self.toaster.open({
          //   text: 'Please try after sometime.',
          //   caption: error,
          //   type: 'dark',
          //   position: 'top-right',
          //   duration: 2000
          // });
          $("#errorplay").fadeIn(); 
          self.isplaying = false;
          self.player.stop();
          self.pushTrack();
          self.CommonService.setLoader(false);
          self.CommonService.setDisabled(true);
          self.CommonService.setisplayerror(true);
          self.CommonService.setisplay(false);
       
        },
        onloaderror: function() {
         
          self.isplaying = false;
          self.player.stop();
          self.pushTrack();
          self.CommonService.setLoader(false);
          self.CommonService.setDisabled(true);
          self.CommonService.setisplayerror(true);
          self.CommonService.setisplay(false);
          // self.toaster.open({
          //   text: 'Please try after sometime.',
          //   caption: 'Station Not Available',
          //   type: 'dark',
          //   position: 'top-right',
          //   duration: 2000
          // });
          $("#errorplay").fadeIn(); 
        },
  
      });
   // }
 }
  async getStream(stream_link){
    const data = await this.homeService.streamLink(stream_link).toPromise();
    return data['data'].stream_link;
  }
  togglePlayerView(e) {
    e.stopPropagation();
    var element = document.querySelector(".playerView");
    element.classList.toggle("close");
  }
  toggelPlayer(event,pause){
    //console.log(" this.volume", this.volume);
    this.bvolume = this.volume;
    this.ngvolume = this.volume * 100; 
    //console.log(" this.ngvolume", this.ngvolume);
    this.player.volume(this.volume);
    this.CommonService.setvolume(this.volume);
    this.CommonService.setvolume(this.volume);
    this.CommonService.setbvolume(this.bvolume);
    event.preventDefault();
    event.stopPropagation();
    if(this.isDisabled) {
      return false;

    }
    
    this.isplaying = !pause;
    if(pause) {
      this.player.pause();
      this.CommonService.setisplay(false);
      this.bvolume = 0;
      this.CommonService.setbvolume(this.bvolume);
   } else {
    this.player.play();

  
    this.CommonService.setisplay(true);
  
   }
  }
  toggelVolume(event,state){
    //console.log(" this.volume", this.volume);
    event.preventDefault();
    event.stopPropagation();
    if(state) {
      //console.log('this.statevolume',this.volume);
      this.player.volume(0);
      this.bvolume = 0;
      this.ngvolume = 0; 
      //this.CommonService.setvolume(0);
      this.CommonService.setbvolume(this.bvolume);
    } else {
      //console.log('this.volume',this.volume);
      if(this.volume == 0.0001){
        this.volume = 0.10;
      }
     
      this.bvolume = this.volume;
      this.ngvolume = this.volume * 100; 
      this.player.volume(this.volume);
      this.CommonService.setvolume(this.volume);
      this.CommonService.setbvolume(this.bvolume);
      
    }

  }
  setRam( value){
    //console.log('this.selectedRam',value);
    //event.preventDefault();
     //event.stopPropagation();
     this.ngvolume = value; 
     value = value / 100;
     //console.log('this.selectedRam',value);
     this.volume = value;
     this.bvolume =  value;
     this.CommonService.setvolume(this.volume);
     this.player.volume(this.volume);
    
   }
onSlide(event) {
    //event.preventDefault();
    //event.stopPropagation();
   //console.log("event.value",event.value);
   var value = event.value;
   value = value / 100;
   //console.log('this.selectedRam',value);
   this.volume = value;
   this.bvolume =  value;
   //console.log("this.bvolume",this.bvolume);
   this.CommonService.setvolume(this.volume);
   this.CommonService.setbvolume(this.bvolume);
   if(this.bvolume == 0.0001) {
    this.bvolume = 0;
    this.CommonService.setbvolume(0);
   }
   this.player.volume(this.volume);
}
disabledprop(event){
  //console.log("event",event);
   event.preventDefault();
   event.stopPropagation();
}
favouites(stationId , state ){
  event.preventDefault();
  event.stopPropagation();
  const self = this;
  if (isPlatformBrowser(this.platformId)) {
  self.user =  JSON.parse(sessionStorage.getItem('user')) || null;
  }
  this.authenticationService.authenticated().subscribe((res) => {
    //console.log('suth', res);
    if(res.authenticated === true){
       this.SpinnerService.show();
      self.playerService.favourite({'user_id': self.user.user_id,'shortcode':stationId}).subscribe((data) => {
        //console.log('favdata', data);
        self.isfav = true;
        self.CommonService.setisfav(true);
        self.SpinnerService.hide();
        self.authenticationService.favData(self.user.user_id).subscribe((data) => {
          if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem('favdata', JSON.stringify(data.data));
          }
        });
      }),
      ((error) => {
        self.SpinnerService.hide();
       // self.toastr.error('Something went wrong. Please try again');
     });
    } else {
      self.CommonService.setisLogin(true);
      return false;
    }
  },(error) => {
     
  });
} 
unfavouites(stationId , state){
  event.preventDefault();
  event.stopPropagation();
  const self = this;
  if (isPlatformBrowser(this.platformId)) {
  self.user =  JSON.parse(sessionStorage.getItem('user')) || null;
  }
  this.SpinnerService.show();
  self.playerService.favourite({'user_id': self.user.user_id,'shortcode': stationId,'type':'delete'}).subscribe((data) => {
    //console.log('unfavdata', data);
    self.isfav = false;
    self.CommonService.setisfav(false);
    self.SpinnerService.hide();
    self.authenticationService.favData(self.user.user_id).subscribe((data) => {
      if (isPlatformBrowser(this.platformId)) {
       
      sessionStorage.setItem('favdata', JSON.stringify(data.data));
      }
    });
  }),
  ((error) => {
    self.SpinnerService.hide();
   // self.toastr.error('Something went wrong. Please try again');

  });
}
isfavourite(){
  var favdata = JSON.parse(sessionStorage.getItem('favdata')) || [];
  const result = favdata.filter(x => x.id ===  this.stationId);
  if(result.length > 0) {
     // this.isfav =  true;
      this.CommonService.setisfav(true);
  } else {
    //this.isfav =  false;
    this.CommonService.setisfav(false);
  }
  //console.log('homeresult',result);
}
ngAfterViewInit(): void {
  this.router.events.subscribe(event => {
    // I check for isPlatformBrowser here because I'm using Angular Universal, you may not need it
    if (event instanceof NavigationEnd  && isPlatformBrowser(this.platformId)) {
      ga('set', 'page', event.urlAfterRedirects);
      ga('send', 'pageview');
    }
  });
}
ngOnDestroy() {
    this.routesubscription.unsubscribe();
}
dismissModal1(){
  $(".modal1").fadeOut(); 
}
next(event,data){
 // console.log("next",data);
  event.preventDefault();
  event.stopPropagation();
 data.stationtype = 1;
 data.offset = this.currentindex;
 const self = this; 
// console.log("index data",this.currentindex);
if(data.type == 'home') {
  if(data.data[this.currentindex] ){
   // console.log("data.data[this.currentindex]",data.data[this.currentindex]);
    if(this.player) {
      this.player.stop();
    }
    this.CommonService.setisnext(true);
    this.CommonService.changeCurrentTrack({'activetrack':  data.data[this.currentindex]});

    this.CommonService.setisprevNextplaying(true);
   // self.start(data.data[this.currentindex])

    data.offset = data.offset + 1;
    this.currentindex =  data.offset;
  
  }else {
    return false;
  }
 //console.log("dddddd",data.data[this.currentindex]);

 } else {
  this.playerService.prevnext(data).subscribe((res) => {
    // console.log('res',res);
    data.offset = data.offset + 1;
    this.currentindex =  data.offset;
     if(!res['data']) {
       this.SpinnerService.hide();
     
       return false;
     } else {
       if(res['data'][0]){
        if(this.player) {
          this.player.stop();
        }
        this.CommonService.setisnext(true);
        this.CommonService.changeCurrentTrack({'activetrack':  res['data'][0]});
        this.CommonService.setisprevNextplaying(true);
       // self.start(res['data'][0]);
        
       }else {
         //self.toaster.error("");
        // console.log("prenext error");
         
       }
     
     }
   
    // if(!res['data']) {
    //    this.router.navigate(['/']);
    //  }

   // console.log(" this.currentindex",  this.currentindex);


},(error) => {
this.router.navigate(['/']);
this.SpinnerService.hide();
});
}
  

}
prev(event,data){
  event.preventDefault();
  event.stopPropagation();
  const self = this; 
  //console.log(" this.currentindex",  this.currentindex);
  //console.log("prev",data);
  
  data.stationtype = 0;
  data.offset = this.currentindex;
  if(data.type == 'home') {
//console.log("data.offset",data.offset);
data.offset = data.offset - 1;
this.currentindex =  data.offset;
if(data.data[this.currentindex -1] ){
  if(this.player) {
    this.player.stop();
  }
  this.CommonService.setisnext(true);
  this.CommonService.changeCurrentTrack({'activetrack':  data.data[this.currentindex - 1]});
  this.CommonService.setisprevNextplaying(true);
 // self.start(data.data[this.currentindex - 1]);


}else {
  return false;
}
console.log("dddddd",data.data[this.currentindex]);

  } else {
    this.playerService.prevnext(data).subscribe((res) => {
      // console.log('res',res);
      data.offset = data.offset - 1;
      this.currentindex =  data.offset;
       if(!res['data']) {
         this.SpinnerService.hide();
       
         return false;
       } else {
         if(res['data'][0]){
          if(this.player) {
            this.player.stop();
          }
          this.CommonService.setisnext(true);
          this.CommonService.changeCurrentTrack({'activetrack':  res['data'][0]});
          this.CommonService.setisprevNextplaying(true);
         // self.start(res['data'][0]);
        
         }else {
           //self.toaster.error("");
         //  console.log("prenext error");
           
         }
       
       }
     
      // if(!res['data']) {
      //    this.router.navigate(['/']);
      //  }
  
    //  console.log(" this.currentindex",  this.currentindex);
  
  
  },(error) => {
  this.router.navigate(['/']);
  this.SpinnerService.hide();
  });
  }

}
redirectTo(data){
 
  if(this.stationId !== data.st_id) {
   const self = this;
   if(this.player){
   this.player.stop();
   }
  // setTimeout(() => {
    let newurl = data.slug;
   // newurl = newurl.replace(/\(/g,"").replace(/\)/g,"");
    //console.log('newurl',newurl);
    self.router.onSameUrlNavigation = 'reload';
    // self.router.navigateByUrl(newurl).then(() => {
    
    //   self.router.onSameUrlNavigation = 'ignore';
    //   self.contsct();
    //   self.ngOn();
    //   self.listingData();
    // });
  }
 
  //  }, 1000);
 
  
     
  }

}
